<template>
  <div>
    <fieldset
      :id="id"
      :class="[
        {
          'lni-c-form-control--required': required,
          '--dense': dense,
          '--filled': filled,
          '--outlined': !filled,
        },
        classString,
      ]"
      class="lni-c-input-date__wrapper"
      @focus="focusOnInput"
      @focusout="onFocusout"
      @focusin="onFocusin"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
    >
      <legend>{{ labelText }}</legend>
      <div class="lni-c-input-date__inner-wrapper lni-u-flex lni-u-flex-column">
        <p>{{ helperText }}</p>
        <div
          class="lni-u-flex lni-u-flex-wrap lni-u-items-start"
          @focusin="calendarIsOpen = false"
        >
          <lni-input-text
            :id="`${id}_month`"
            ref="month"
            class="lni-u-mr1"
            :value="monthValue"
            :name="`${id}_month`"
            :filled="filled"
            :dense="dense"
            :required="required"
            :disabled="disabled"
            :aria-describedby="`${id}_helper-text`"
            :aria-labelledby="labelledby"
            :errorText="errorText"
            :labelBefore="true"
            labelText="Month"
            inputmode="numeric"
            type="number"
            min="1"
            max="12"
            :pattern="/^(0?[1-9]|1[012])$/"
            maxlength="2"
            :customWidth="2"
            :customValidityAction="`${id}/monthCustomValidation`"
            :hasInlineMessages="false"
          />
          <lni-input-text
            :id="`${id}_day`"
            ref="day"
            class="lni-u-mr1"
            :value="dayValue"
            :name="`${id}_day`"
            :filled="filled"
            :dense="dense"
            :required="required"
            :disabled="disabled"
            :aria-describedby="`${id}_helper-text`"
            :aria-labelledby="labelledby"
            :errorText="errorText"
            :labelBefore="true"
            labelText="Day"
            inputmode="numeric"
            type="number"
            min="1"
            max="31"
            :pattern="/^(0?[1-9]|[12][0-9]|3[01])$/"
            maxlength="2"
            :customWidth="2"
            :customValidityAction="`${id}/dayCustomValidation`"
            :hasInlineMessages="false"
          />

          <lni-input-text
            :id="`${id}_year`"
            ref="year"
            class="lni-u-mr1"
            :value="yearValue"
            :name="`${id}_year`"
            :filled="filled"
            :dense="dense"
            :required="required"
            :disabled="disabled"
            :aria-describedby="`${id}_helper-text`"
            :aria-labelledby="labelledby"
            :errorText="errorText"
            labelText="Year"
            :labelBefore="true"
            inputmode="numeric"
            :pattern="/^(19|20)\d{2}$/"
            type="number"
            :min="$store.getters[`${id}/earliestYear`]"
            :max="$store.getters[`${id}/latestYear`]"
            maxlength="4"
            :customWidth="4"
            :customValidityAction="`${id}/yearCustomValidation`"
            :hasInlineMessages="false"
          />
          <button
            class="lni-c-input-date__picker-toggle"
            data-toggle
            :aria-expanded="calendarIsOpen"
            aria-label="Visual date picker"
            @click="toggleCalendar"
          >
            <span aria-hidden="true" class="lni-c-icon lnicon--calendar" />
          </button>
        </div>
        <div
          v-if="hasInlineMessages && hasErrorText"
          :id="`${id}_helper-text`"
          :class="hasErrorText ? 'lni-c-text-field__error-message' : null"
          class="lni-c-text-field__helper-text lni-u-type--xxs lni-u-line-height--tight"
        >
          <template v-show="hasErrorText">
            <p>{{ errorText }}</p>
          </template>
          <template v-if="!hasErrorText">
            <slot name="helperText" />
          </template>
        </div>
      </div>
    </fieldset>
    <transition name="slide-from-top">
      <div
        v-show="calendarIsOpen"
        ref="datePickerParent"
        class="lni-c-input-date__picker-parent lni-u-pt1"
      >
        <input
          ref="fullDate"
          type="hidden"
          aria-hidden="true"
          class="lni-u-visually-hidden"
          data-input
        />
      </div>
    </transition>
  </div>
</template>

<script>
import flatpickr from "flatpickr";
import text from "@gov.wa.lni/framework.one-lni/source/locale/en.js";
import interpolate from "@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js";

const initializeCalendar = function initializeCalendar(component) {
  const fp = flatpickr(component.$el, {
    allowInput: true,
    clickOpens: false,
    dateFormat: "m/d/Y",
    maxDate: component.maxDate,
    minDate: component.minDate,
    wrap: true,
    appendTo: component.$refs.datePickerParent,
    inline: true,
    onChange(selectedDates, dateString) {
      component.handleFlatPickrChange(selectedDates, dateString);
    },
  });
  return fp;
};

export default {
  name: "LniInputDate",

  data() {
    return {
      calendar: null,
      calendarIsOpen: false,
      hasFocus: false,
      focusedElement: false,
    };
  },
  computed: {
    hasErrorText() {
      return !!this.$store.state[this.id].errorText;
    },
  },
  watch: {
    hasFocus(val) {
      if (!val) {
        //focus has left
        this.$emit("blur");
      }
    },
  },

  mounted() {
    if (this.calendar) {
      return;
    }
    if (this.value) {
      this.setIndividualValues(this.value);
    }

    this.setHasFocus();
    this.addCustomValidations();
    this.updateValidity();
    this.calendar = initializeCalendar(this);

    this.$watch("value", (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.setIndividualValues(newValue);
      }
    });
  },
  beforeUnmount() {
    if (this.calendar === null) {
      return;
    }

    this.calendar.destroy();
    this.calendar = null;
  },
  methods: {
    onFocusout() {
      this.setHasFocus();
    },
    onFocusin() {
      this.setHasFocus();
    },
    focusOnInput() {
      this.$refs.month.$refs.input.focus();
    },
    setHasFocus() {
      const container = this.$el;

      setTimeout(
        (container) => {
          const focused = document.activeElement;
          this.focusedElement = focused.id;
          if (container.contains(focused)) {
            this.hasFocus = true;
          } else {
            this.hasFocus = false;
          }
        },
        1,
        container
      );
    },
    toggleCalendar(e) {
      e.preventDefault();
      this.calendarIsOpen = !this.calendarIsOpen;
    },
    onInput() {
      this.updateValidity().then(() => {
        if (this.hasErrorText) {
          this.validate();
        }
      });

      this.dispatchEvent("inputAction");
    },
    onChange() {
      this.setCombinedValues();
      this.triggerChange();
    },
    onBlur() {
      this.dispatchEvent("blurAction");
    },
    validate() {
      // This will take the errors from the individual date components
      // and put them into the error text of this component.
      this.$store.dispatch(`${this.id}/customValidate`, {
        targetId: this.id,
      });
    },
    updateValidity() {
      return this.$store.dispatch(`updateValidity`, {
        targetId: this.id,
      });
    },
    handleFlatPickrChange(selectedDates, dateString) {
      const prevMonth = this.$store.getters[`${this.id}/monthValue`];
      const prevDay = this.$store.getters[`${this.id}/dayValue`];
      const prevYear = this.$store.getters[`${this.id}/yearValue`];
      if (dateString && dateString !== `${prevMonth}/${prevDay}/${prevYear}`) {
        this.$store.commit(`${this.id}_month/setDirty`, {
          value: true,
        });
        this.$store.commit(`${this.id}_day/setDirty`, {
          value: true,
        });
        this.$store.commit(`${this.id}_year/setDirty`, {
          value: true,
        });
        this.setIndividualValues(dateString);

        this.$store.commit(
          "setAttribute",
          {
            id: this.id,
            attribute: "value",
            value: dateString,
          },
          {
            root: true,
          }
        );

        this.calendarIsOpen = !this.calendarIsOpen;
        this.triggerChange();
      }
    },
    setIndividualValues(dateString) {
      const datePartCount = 3;
      let month = "";
      let day = "";
      let year = "";

      // This function is used to reset the value and thus the input is an
      // empty string or when a value has been selected in the datepicker and
      // then it is a correctly formatted date string.  We only handle those
      // two cases.
      if (dateString !== "") {
        const values = dateString.split("/");
        if (values.length !== datePartCount) {
          return;
        }

        month = values[0];
        day = values[1];
        year = values[2];
      }

      this.$store.dispatch(`${this.id}/updateMonth`, month);
      this.$store.dispatch(`${this.id}/updateDay`, day);
      this.$store.dispatch(`${this.id}/updateYear`, year);
    },
    setCombinedValues() {
      const month = this.$store.getters[`${this.id}/monthValue`];
      const day = this.$store.getters[`${this.id}/dayValue`];
      const year = this.$store.getters[`${this.id}/yearValue`];
      if (month.length && day.length && year.length) {
        this.$store.commit(
          "setAttribute",
          {
            id: this.id,
            attribute: "value",
            value: `${month}/${day}/${year}`,
          },
          {
            root: true,
          }
        );
      } else if (month.length === 0 && day.length === 0 && year.length === 0) {
        this.$store.commit(
          "setAttribute",
          {
            id: this.id,
            attribute: "value",
            value: ``,
          },
          {
            root: true,
          }
        );
      }

      this.calendar.setDate(this.value);
    },
    addCustomValidations() {
      const messages = text["lni-input-date"].messages;
      this.$store.commit(`${this.id}_month/addValidityTest`, {
        validityTest: "invalidMonth",
        global: () => messages.invalidMonth.global,
        inline: () => messages.invalidMonth.inline,
      });
      this.$store.commit(`${this.id}_day/addValidityTest`, {
        validityTest: "monthEnd",
        global: () => messages.monthEnd.global,
        inline: () => messages.monthEnd.inline,
      });
      this.$store.commit(`${this.id}_day/addValidityTest`, {
        validityTest: "invalidDay",
        global: () => messages.invalidDay.global,
        inline: () => messages.invalidDay.inline,
      });
      this.$store.commit(`${this.id}_year/addValidityTest`, {
        validityTest: "invalidYear",
        global: () => messages.invalidYear.global,
        inline: () => messages.invalidYear.inline,
      });
      this.$store.commit(`${this.id}_year/addValidityTest`, {
        validityTest: "leapYear",
        global: (state) =>
          interpolate(messages.leapYear.global, {
            value: state.value,
          }),
        inline: (state) =>
          interpolate(messages.leapYear.inline, {
            value: state.value,
          }),
      });
    },
    triggerChange() {
      this.updateValidity().then(() => {
        const monthDirty = this.$store.state[`${this.id}_month`].flags.dirty;
        const dayDirty = this.$store.state[`${this.id}_day`].flags.dirty;
        const yearDirty = this.$store.state[`${this.id}_year`].flags.dirty;
        if (monthDirty && dayDirty && yearDirty) {
          // We only want to bring the errors from the sub components
          // and show them once all of the inputs have been interacted with
          this.validate();
        }
        this.dispatchEvent("changeAction");
      });
    },
  },
};
</script>

<style>
@import "../../../node_modules/flatpickr/dist/flatpickr.min.css";
</style>